import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

/**
 * ThrottleActionService
 *
 * A service to prevent rapid, successive execution of actions by implementing
 * a throttling mechanism. This service allows functions passed to it to be
 * executed only once within a defined throttle time interval, preventing
 * unintended double-clicks or rapid firing of actions.
 *
 * This service is especially useful in scenarios where multiple components
 * may require throttled button clicks, API requests, or event handlers.
 *
 * Usage:
 * 1. Inject the ThrottleActionService in a component.
 * 2. Call the `execute` method with a function to throttle its execution.
 *
 * Example:
 * ```typescript
 * constructor(private throttleService: ThrottleActionService) {}
 *
 * onButtonClick() {
 *   this.throttleService.execute(this.someAction);
 * }
 *
 * someAction() {
 *   console.log('Action executed');
 * }
 * ```
 */
@Injectable({
    providedIn: 'root',
})
export class ThrottleActionService {
    /**
     * Subject that handles incoming actions to be throttled.
     * Each action passed into this subject will be executed based on
     * the defined throttle time interval.
     */
    private actionSubject = new Subject<() => void>();

    public constructor() {
        // Sets up a throttling mechanism on the actionSubject to execute each action only once
        // per throttleTime interval (in this case, every 300 ms).
        this.actionSubject.pipe(throttleTime(300)).subscribe(action => {
            action();
        });
    }

    /**
     * Executes a given function, ensuring it is throttled according to the defined throttle time.
     *
     * @param action - The function to be executed. The function will only execute once within
     * the throttle time interval, regardless of how many times this method is called.
     *
     * Example usage:
     * ```typescript
     * this.throttleService.execute(this.myFunction);
     * ```
     */
    public execute(action: () => void): void {
        this.actionSubject.next(action);
    }
}
